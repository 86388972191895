<template>
<div class="wrap-banner slide-banner-top" v-if="listData.length">
  <VueSlickCarousel v-bind="settingSlide"
                    class="main-slide">
    <div class="bg-img"
         v-for="item in listData"
         :key="item.id"
         :style="{backgroundImage: `url(${item.file.path})`}">
      <a @click="handleRedirectUrl(item.redirect_url)">
        <img class="pc" :src="item.file.path" alt="banner">
        <img class="sp" :src="item.thumbnail_sp.path" alt="banner">
      </a>
    </div>
  </VueSlickCarousel>
</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import Common from '@/mixins/common.mixin'
import { PAGE_NATIVE } from '@/enum/title-header'
import { mapState } from 'vuex'

export default {
  name: 'SlideBanner',

  components: {
    VueSlickCarousel
  },

  props: {
    listData: {
      type: Array,
      default: () => []
    }
  },
  mixins: [Common],
  computed: {
    ...mapState('auth', ['token'])
  },
  data () {
    return {
      settingSlide: {
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false
      },
      hostName: process.env.VUE_APP_USER_DOMAIN
    }
  },

  methods: {
    checkIsCategoryList (url) {
      const routeCateList = ['category', 'survey', 'receipt']
      const isCateList = !!routeCateList.find(route => url.includes(route))
      return isCateList
    },
    handleRedirectUrl (url) {
      //  CHECK URL INTERNAL OR EXTERNAL
      const parseUrl = new URL(url)
      // REDIRECT PAGE NATIVE
      let flagPageNative = false
      PAGE_NATIVE.forEach((elm) => {
        if (elm.path === parseUrl.pathname) {
          flagPageNative = true
        }
      })

      if (this.hostName.includes(parseUrl.origin)) {
        if (flagPageNative) {
          const namePageNative = PAGE_NATIVE.find(x => x.path === parseUrl.pathname).name
          // CHECK URL IS LINK TO LOGIN OR REGISTER
          if (this.token &&
            (namePageNative === 'Login' || namePageNative === 'Register')) {
            this.openPageNative('Index')
          } else if (!this.token && namePageNative !== 'Index' && namePageNative !== 'Register') {
            this.openPageNative('Login')
          } else this.openPageNative(namePageNative)
        } else {
          if (!this.checkWebview()) {
            if (url.includes('/lp/')) {
              window.open(url, '_self')
            } else if (url.includes('/cp/')) {
              window.open(url, '_blank')
            } else if (this.checkIsCategoryList(url)) {
              window.open(url, '_self')
            } else this.$router.push({ path: parseUrl.pathname })
          } else {
            // IF URL INTERNAL OPEN IN WEBVIEW
            if (this.checkWebview()) {
              const routeTriggerEventApp = ['feature-list', 'receipt', 'survey']
              let routeName = routeTriggerEventApp.find(route => url.includes(route))
              const isTriggerEventApp = !!routeName
              if (isTriggerEventApp) {
                if (routeName === 'feature-list') {
                  routeName = 'feature'
                }
                const nameEvent = `PAGE_REDIRECT_${routeName.toUpperCase()}`
                this.triggerEventApp(nameEvent, {})
              } else {
                this.triggerEventApp(
                  'OPEN_WEBVIEW',
                  { url: url }
                )
              }
            }
          }
        }
      } else this.openUrl(url)
      // if (this.checkWebview()) {
      //   this.triggerEventApp(
      //     'OPEN_BROWSER',
      //     { url: url }
      //   )
      // } else window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap-banner {
  .main-slide {
    .bg-img {
      padding: 35px 15px;
      position: relative;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: 100%, 100%;
      @media #{$info-tablet-horizontal} {
        padding: 35px 50px;
      }
      @media #{$info-phone} {
        padding: 20px 20px 45px;
        background-size: auto, 100%;
      }
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(20px);
        background-color: rgba(255, 255, 255, 0.5);
        left: 0;
        top: 0;
        z-index: -1;
      }
      a {
        max-width: 1130px;
        display: block;
        margin: auto;
      }
      img {
        border-radius: 6px;
        width: 100%;
        height: 388px;
        //object-fit: cover;
        margin: auto;
        @media #{$info-phone} {
          height: 250px;
          width: 300px;
          object-fit: contain;
        }
      }
    }
  }
}
</style>
