<template>
  <div class="list-action">
    <div class="container-action">
      <div v-for="(action, index) in listAction" :key="action.title" class="action-item" :class="{'hidden-btn': action.action === 'search', 'action-line-01': index < 5}" @click="handleLink(action)">
          <p class="action-title" > {{ convertTitle(action.title) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Common from '@/mixins/common.mixin'
import { mapState } from 'vuex'
import { SERVICE_OFFERWALL } from '@/enum/options'

export default {
  name: 'ListAction',

  mixins: [Common],
  computed: {
    ...mapState('category', ['categoryListTitle'])
  },
  data () {
    return {
      SERVICE_OFFERWALL,
      listAction: [
        {
          action: 'search',
          title: '検索'
        },
        {
          id: 'all',
          action: 'new',
          title: '新着',
          typeColor: '1'
        },
        {
          action: 'smaad',
          title: 'アプリ'
        },
        {
          action: 'small',
          title: '応募',
          typeColor: '1',
          midTitle: '無料'
        },
        {
          action: 'small',
          title: 'アンケート',
          typeColor: '1',
          midTitle: '無料'
        },
        {
          action: 'small',
          title: '高額ポイント',
          typeColor: '1',
          midTitle: 'トピックで探す'
        },
        {
          action: 'mid',
          title: '無料',
          typeColor: '1'
        },
        {
          action: 'mychips',
          title: 'ゲーム'
        },
        {
          action: 'mid',
          title: 'ショッピング',
          typeColor: '1'
        },
        {
          action: 'mid',
          title: '旅行',
          typeColor: '1'
        }
      ]
    }
  },
  created () {
    this.getListAction()
  },
  methods: {
    getListAction () {
      if (this.categoryListTitle.length) {
        const arrFilterMid = [...this.listAction]
        arrFilterMid.map((item, index) => {
          if (item.action === 'mid') {
            this.categoryListTitle.map(x => {
              const itemAction = x.children.find(v => v.title === item.title)
              if (itemAction) {
                arrFilterMid[index] = { ...item, id: itemAction.id }
              }
            })
          } else if (item.action === 'small') {
            this.categoryListTitle.map(x => x.children.map(v => {
              if (item.midTitle === v.title) {
                const itemAction = v.children.find(y => y.title === item.title)
                if (itemAction) {
                  arrFilterMid[index] = { ...item, id: itemAction.id }
                }
              }
            }))
          }
        })
        this.listAction = arrFilterMid
      }
    },
    convertTitle (title) {
      switch (title) {
        case '高額ポイント':
          return '高額'
        case 'ショッピング':
          return '買い物'
        default:
          return title
      }
    },
    handleLink (item) {
      switch (item.action) {
        case 'search':
          return this.checkWebview() ? this.triggerEventApp('PAGE_REDIRECT_SEARCH', {}) : this.$emit('open-search')
        case 'mid': case 'small': case 'new':
          return this.redirectPage(item)
        case 'smaad':
          return this.$emit('offer-wall', SERVICE_OFFERWALL.SMAAD)
        case 'mychips':
          return this.$emit('offer-wall', SERVICE_OFFERWALL.MYCHIPS)
      }
    },
    redirectPage (item) {
      const { id, typeColor, action: level } = item
      if (this.checkWebview()) {
        const matchValue = {
          id,
          '#color': typeColor,
          '#levelCategory': level
        }
        const url = process.env.VUE_APP_CATEGORY_DOMAIN.replace(/id|#color|#levelCategory/gi, function (key) {
          return matchValue[key]
        })
        this.triggerEventApp(
          'OPEN_WEBVIEW',
          { url: url }
        )
      } else {
        this.$router.push({ name: 'CategoryList.Category', params: { id, title: !id ? item.title : '' }, query: { typeColor, level } })
      }
    }
  },
  watch: {
    categoryListTitle: {
      handler () {
        this.getListAction()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.list-action {
  .container-action {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    opacity: 1;
    padding: 20px 0px;
    @media #{$info-tablet-horizontal} {
      padding: 20px 0;
    }
    @media #{$info-phone} {
      padding: 20px 0;
    }
    .action-item {
      width: 20%;
      padding: 2px;
      &:hover {
        cursor: pointer;
      }
      &.hidden-btn {
        display: none;
        @media #{$info-phone} {
          display: block
        }
      }
      &.action-line-01 {
        width: 25%;
        @media #{$info-phone} {
          width: 20%;
        }
      }
      @media #{$info-phone} {
        &:nth-child(1), &:nth-child(2) {
          width: 50%;
        }
        &:nth-child(n + 3) {
          width: 25%;
        }
      }

      .action-title {
        border: 1px solid #7C934C80;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        background: #FFFFFF;
        text-align: center;
        color: #7E954F;
        font-weight: 600;
        font-size: 18px;
        padding: 15px 0;
        @media #{$info-tablet-horizontal} {
          font-size: 16px;
        }
        @media #{$info-tablet-vertical} {
          font-size: 14px;
        }
        @media #{$info-phone} {
          text-align: center;
          font-size: 11px;
        }
      }
      .title-two-row {
        flex-direction: column;
      }
    }
  }

}
</style>
