<template>
  <main :class="{'pt-0' : checkWebview(), 'padding-has-caution': isHasCaution }">

    <!-- slide banner -->
    <SlideBanner :list-data="bannerList" />

    <!-- content home page -->
    <div class="home-content container main-has-sidebar">
      <!-- sidebar on pc -->
      <sidebar class="pc"/>

      <div class="main-content">

        <!-- list action -->
        <ListAction @open-search="showSearchModal" @offer-wall="handleOfferwall"/>

        <div class="offerwall" v-if="isShowOfferWall">
          <div class="smaAD">
            <picture>
              <source media="(min-width:768px)" srcset="../assets/images/smaad_pc_app.png">
              <source media="(max-width:499px)" srcset="../assets/images/smaad_sp_app.png">
              <img src="../assets/images/smaad_pc_app.png" alt="マイページの見方" class="switching" @click="handleOfferwall(SERVICE_OFFERWALL.SMAAD)">
            </picture>
          </div>
          <div class="my-chips">
            <picture>
              <source media="(min-width:768px)" srcset="../assets/images/mychips-pc.png">
              <source media="(max-width:499px)" srcset="../assets/images/mychips-sp.png">
              <img src="../assets/images/mychips-pc.png" alt="マイページの見方" class="switching" @click="handleOfferwall(SERVICE_OFFERWALL.MYCHIPS)">
            </picture>
          </div>
        </div>

        <div class="gmo">
          <picture>
            <source media="(min-width:768px)" srcset="../assets/images/gmo-pc.png">
            <source media="(max-width:499px)" srcset="../assets/images/gmo-sp.png">
            <img src="../assets/images/gmo-pc.png" alt="マイページの見方" class="switching" @click="redirectToSurveyWallPage()">
          </picture>
        </div>

        <!-- list pickup -->
        <ListPickupNew  />

        <!-- list new -->
        <ListNew />
        <!-- list ranking category -->
        <ListRanking :listCate="rankingList" class="wrap-ranking-cate" />

        <!-- list category in tab -->
        <ListCategoryTab  />
        <!-- block title list ranking feature -->
        <div class="block-title block-title-feature"
             v-if="featureList.length > 0">
          <h2 class="title-orange">FEATURE</h2>
          <span class="sub-orange">特集</span>
        </div>

        <ListFeature :list="featureList"
                      class="feature-home"/>

        <!-- button show more -->
        <button @click="redirectFeature" class="btn-show-more" v-if="featureList.length > 0">
          もっと見る
        </button>

      </div>
    </div>
    <!-- MODAL RECOMMEND ADV -->
    <Modal @close-modal="closeModal"
           class="modal-center modal-notify"
           :class="{ [typeColor]: !!typeColor }"
           v-if="flagModal && listRecommendModal.length">
          <div slot="body" class="responsive-height
          ">
          <div class="block-vertical" :class="{ [typeColor]: !!typeColor }">
            <ListItem :list="listRecommendModal" titleModal="スタッフいちおし!"  hasBackground  mode="vertical" isChangeBackground isInModal/>
          </div>

            <button
                    @click="closeModal"
                    v-html="'閉じる'"
                    class="btn-modal-recommend"/>
          </div>
    </Modal>
  </main>
</template>

<script>
import Sidebar from '@/layouts/Sidebar'
import SlideBanner from '@/components/home/SlideBanner'
import ListRanking from '@/components/home/ListRanking'
import ListAction from '@/components//home/ListAction'
import ListFeature from '@/components/ListFeature'
import Modal from '@/components/advertising-detail/Modal'
import ListCategoryTab from '@/components/home/ListCategoryTab.vue'
import ListPickupNew from '@/components/home/ListPickupNew.vue'
import ListNew from '@/components/home/ListNew.vue'
import ListItem from '@/components/ListItem.vue'

import Vue from 'vue'
import store from '@/store'
import { mapState, mapActions } from 'vuex'
import Common from '@/mixins/common.mixin'
import GMOResearch from '@/mixins/gmoResearch.mixin'
import { SERVICE_OFFERWALL } from '@/enum/options'
import { isMobile, isIOS } from 'mobile-device-detect'
import moment from 'moment'
import Cookie from 'js-cookie'

export default {
  name: 'Index',

  components: {
    ListFeature,
    ListRanking,
    SlideBanner,
    Sidebar,
    ListAction,
    Modal,
    ListCategoryTab,
    ListPickupNew,
    ListNew,
    ListItem
  },

  mixins: [Common, GMOResearch],
  data () {
    return {
      typeColor: 'cate-pink',
      flagModal: false,
      SERVICE_OFFERWALL,
      lpSrc: '//static.smaad.net/script/lp.js'
    }
  },

  beforeRouteLeave (to, from, next) {
    this.destroySmaScripts()
    next()
  },

  computed: {
    ...mapState('feature', ['featureList']),
    ...mapState('category', ['categoryListTitle']),
    ...mapState('categoryRanking', ['rankingList']),
    ...mapState('banner', ['bannerList']),
    ...mapState('auth', ['profile']),
    ...mapState('advertises', ['listRecommendModal']),
    ...mapState('advertisesMid', ['listMidTab']),

    isShowOfferWall () {
      const isOnNativeAppIOS = this.checkWebview() && isIOS && isMobile
      if (isOnNativeAppIOS) return false
      else return true
    },

    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  },

  // feature-list in top native
  created () {
    const paramsFeature = {
      page: 1,
      perPage: 6,
      orderBy: 'order',
      sortedBy: 'asc'
    }

    const listTab = store.state.advertisesMid.listMidTab
    const isMid = listTab.length && listTab[0].isMid
    Promise.all([
      store.dispatch('feature/getListFeature', paramsFeature),
      store.dispatch('categoryRanking/getList', { with: 'category,advertiseRankings.advertise' }),
      store.dispatch('banner/getList', { page: 1, perPage: 10 }),
      store.dispatch('pickup/getList', { with: 'advertise' }),
      // store.dispatch('advertises/getListAdvertises', paramsListAdv)
      store.dispatch('advertises/getListNew', { page: 1, perPage: 8 }),
      !Cookie.get('tracking_modal') && store.dispatch('advertises/getListRecommendModal'),
      listTab.length && (isMid ? store.dispatch('advertisesMid/getListAdvertisesByMid', [listTab[0].id, { page: 1, perPage: 8 }]) : store.dispatch('advertisesMid/getListAdvertisesBySmall', [listTab[0].id, { page: 1, perPage: 8 }]))
    ])

    if (!this.checkWebview() && !Cookie.get('tracking_modal')) {
      this.flagModal = true
    }
    this.getListTab()
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
      document.addEventListener('VISIBLE_MODAL', (event) => {
        if (event.detail.toggle.toLowerCase() === 'true') {
          this.flagModal = true
        }
      })
    }
    this.initSmaScripts()
  },
  methods: {
    ...mapActions('advertisesMid', ['getListAdvertisesByMid', 'getListAdvertisesBySmall']),
    async initSmaScripts () {
      await Vue.loadScript(this.lpSrc, true)
    },
    async destroySmaScripts () {
      await Vue.unloadScript(this.lpSrc)
    },
    showSearchModal () {
      this.$emit('openSearch')
    },
    getListTab () {
      if (!this.listMidTab.length && this.categoryListTitle.length) {
        const arrFilterMid = [
          { title: '高額ポイント', isMid: false, typeColor: '1', midTitle: 'トピックで探す' },
          { title: '無料', isMid: true, typeColor: '1' },
          { title: 'ゲーム', isMid: false, typeColor: '1', midTitle: 'アプリ' },
          { title: 'ショッピング', isMid: true, typeColor: '1' },
          { title: '旅行', isMid: true, typeColor: '1' }
        ]
        const tab = []
        arrFilterMid.map(item => {
          if (item.isMid) {
            this.categoryListTitle.map(x => {
              const itemTab = x.children.find(v => v.title === item.title)
              itemTab ? tab.push({ id: itemTab.id, title: itemTab.title, isMid: item.isMid, typeColor: item.typeColor }) : tab.push(item)
            })
          } else {
            this.categoryListTitle.map(x => x.children.map(v => {
              if (item.midTitle === v.title) {
                const itemTab = v.children.find(y => y.title === item.title)
                itemTab ? tab.push({ id: itemTab.id, title: itemTab.title, isMid: item.isMid, typeColor: item.typeColor }) : tab.push(item)
              }
            }))
          }
        })
        const id = tab[0].id
        const params = {
          page: 1,
          perPage: 8
        }
        tab[0].isMid ? this.getListAdvertisesByMid([id, params]) : this.getListAdvertisesBySmall([id, params])
        store.dispatch('advertisesMid/setListMidTab', tab)
      }
    },
    handleOfferwall (serviceId) {
      if (this.profile && Object.keys(this.profile).includes('id')) {
        if (serviceId === SERVICE_OFFERWALL.SMAAD) {
          this.redirectSmaAd()
        } else {
          this.redirectMyChips()
        }
      } else {
        this.openPageNative('Login')
      }
    },
    convertGenderToString (gender) {
      switch (gender) {
        case 1:
          return 'm'
        case 2:
          return 'f'
        default:
          return 'o'
      }
    },
    closeModal () {
      this.flagModal = false
      if (this.checkWebview()) {
        this.triggerEventApp('MODAL_CLOSED', {
          isTopModal: true
        })
      } else Cookie.set('tracking_modal', 1, { expires: 1, secure: true })
    },
    redirectSmaAd () {
      const url = process.env.VUE_APP_SMAAD_DOMAIN.replace('userId', this.profile.id)
      this.openUrl(url)
    },
    redirectMyChips () {
      const userAge = this.profile.birthday ? moment().year() - moment(this.profile.birthday).year() : ''
      const userGender = this.profile.gender ? this.convertGenderToString(this.profile.gender) : ''
      const matchValue = {
        userId: this.profile.id,
        userAge,
        userGender
      }
      const url = process.env.VUE_APP_MYCHIPS_DOMAIN.replace(/userId|userAge|userGender/gi, function (key) {
        return matchValue[key]
      })
      this.openUrl(url)
    },
    redirectFeature () {
      if (this.checkWebview()) {
        this.triggerEventApp('PAGE_REDIRECT_FEATURE', {})
      } else {
        this.$router.push('/feature-list')
      }
    }
  },
  watch: {
    categoryListTitle: {
      handler () {
        this.getListTab()
      }
    }
  }

}
</script>

<style lang="scss" scoped>
main {
  background: #ffffff;
  @media #{$info-phone} {
    padding-top: 41px;
    overflow-x: hidden;
  }
  &.padding-has-caution {
    @media #{$info-phone} {
      padding-top: 92px;
    }
  }
  .home-content {
    background: #ffffff;
    display: flex;
    align-items: flex-start;
    padding-top: 80px;
    padding-bottom: 96px;
    @media #{$info-phone} {
      padding-top: 18px;
      display: block;
      padding-bottom: 72px;
    }
  }
  .main-content {
    flex: 1;
    .show-sidebar {
      font-family: $font-family-title;
      width: 100%;
      height: 44px;
      border-radius: 22px;
      box-shadow: 0 1px 4px rgba(0, 0, 0 , 0.16);
      background: #ffffff;
      display: none;
      align-items: center;
      justify-content: center;
      position: relative;
      @include font-size(16px);
      margin-bottom: 41px;
      @media #{$info-phone} {
        display: flex;
        font-size: 16px;
      }
      img {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .wrap-ranking-cate {
      margin: 96px 0 86px;
      @media #{$info-tablet-horizontal} {
        margin: 96px auto 86px;
      }
      @media #{$info-phone} {
        margin: 72px auto 65px;
      }
    }
    .block-title-feature {
      margin-bottom: 37px;
    }
    .btn-show-more {
      font-family: $font-family-title;
      background: #ffffff;
      display: flex;
      position: relative;
      @include font-size(18px);
      margin: 30px auto 0;
      color: #C66C44;
      @media #{$info-phone} {
        font-size: 16px;
      }
    }

    .offerwall {
      display: flex;
      justify-content: space-between;
      //margin-bottom: 86px;
      margin-bottom: 20px;
      @media #{$info-tablet-vertical} {
        margin-bottom: 65px;
      }
      @media #{$info-phone} {
        margin-bottom: 20px;
      }
      .smaAD {
        width: 49%;
        cursor: pointer;
      }
      .my-chips {
        width: 49%;
        cursor: pointer;
      }
    }

    .gmo {
      cursor: pointer;
      width: 100%;
      margin-bottom: 120px;

      img {
        width: 100%;
      }
    }
  }
}
</style>
