<template>
  <div class="pick-up pickup-container block-vertical" :class="{ [typeColor]: !!typeColor }" >
    <!-- block title pickup -->
    <div class="block-title" >
       <h2 :class="classTitle">CATEGORY</h2>
      <span :class="classSubTitle">カテゴリ別</span>
    </div>

    <template>
      <!-- tab -->
      <div class="common-tab" id="header-tab">
        <!-- menu tab -->
        <div class="menu-tab-container-pc">
          <ul class="menu-tab">
            <li :class="{'active': currentTab === index + 1}"
                        v-for="(item, index ) in listMidTab"
                        :key="item.title"
                        @click="() => changeTab(index, item)">
              {{ convertNameTab(item.title) }}
            </li>
          </ul>
        </div>

        <!-- content tab -->
        <div class="wrap-content-tab">
          <ListItem :list="advertiseListByMid" mode="vertical" :type-color="typeColor" hasShadow hasShowMore @handle-link="handleLink" hasShowMark />
          <div v-if="!advertiseListByMid.length">
            <!-- NOTE DATA EMPTY -->
            <p class="data-empty" >該当するデータが見つかりませんでした。</p>
          </div>
        </div>
      </div>

    </template>

  </div>

  </template>

<script>
import Common from '@/mixins/common.mixin'
import { mapState, mapActions } from 'vuex'
import ListItem from '../ListItem.vue'

export default {
  name: 'ListCategoryTab',

  components: {
    ListItem
  },

  mixins: [Common],

  data () {
    return {
      currentTab: 1,
      typeColor: 'cate-pink',
      // color green || orange
      color: ''
    }
  },
  computed: {
    ...mapState('advertisesMid', ['advertiseListByMid', 'listMidTab']),

    classTitle () {
      return this.color ? (this.color === 'green' ? 'title-green' : 'title-orange') : ''
    },
    classSubTitle () {
      return this.color ? (this.color === 'green' ? 'sub-green' : 'sub-orange') : ''
    }

  },
  methods: {
    ...mapActions('advertisesMid', ['getListAdvertisesByMid', 'getListAdvertisesBySmall']),
    scrollToTab () {
      const header = document.getElementById('header-tab')
      const yCoordinate = header.getBoundingClientRect().top + window.pageYOffset
      let yOffset = 0

      // check device SP plus offset top is covered by header
      if (window.matchMedia('(max-width: 767px)').matches) {
        yOffset -= 45
      }

      window.scrollTo({
        top: yCoordinate + yOffset,
        behavior: 'smooth'
      })
    },
    convertNameTab (title) {
      switch (title) {
        case '高額ポイント':
          return '高額広告'
        case 'ショッピング':
          return 'お買い物'
        default:
          return title
      }
    },
    changeTab (index, item) {
      this.currentTab = index + 1
      item.isMid ? this.getListAdvertisesByMid([item.id, { page: 1, perPage: 8 }]) : this.getListAdvertisesBySmall([item.id, { page: 1, perPage: 8 }])
    },
    handleLink () {
      const tab = this.listMidTab[this.currentTab - 1]
      const { id, typeColor, isMid } = tab
      if (this.checkWebview()) {
        const matchValue = {
          id,
          '#color': typeColor,
          '#levelCategory': isMid ? 'mid' : 'small'
        }
        const url = process.env.VUE_APP_CATEGORY_DOMAIN.replace(/id|#color|#levelCategory/gi, function (key) {
          return matchValue[key]
        })
        this.triggerEventApp(
          'OPEN_WEBVIEW',
          { url: url }
        )
      } else {
        this.$router.push({ name: 'CategoryList.Category', params: { id }, query: { typeColor, level: isMid ? 'mid' : 'small' } })
      }
    }
  }
}
</script>

  <style lang="scss" scoped>
  .pickup-container {
    margin-bottom: 120px;
    &:has(.in-modal) {
      & {
        margin-bottom: 0;
      }
    }
  }
  .block-title {
    h3 {
      label {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 4px;
          background: url("../../assets/images/icon/dot-under-line.svg") no-repeat;
          background-size: contain;
          background-position: center;
          top: 1px;
          left: 0;
        }
      }
    }
  }
    .common-tab {
        /* margin-top: 96px; */
        @media #{$info-phone} {
          /* margin-top: 70px; */
        }
        .menu-tab-container-pc {
          border-bottom: 2px solid #CACBC6;
          @media #{$info-tablet-vertical} {
              width: 100%;
              border-bottom: none;
            }
          @media #{$info-phone} {
            border-bottom: none;
          }
          .menu-tab {
            border-bottom: none;
            width: 80%;
            margin: 0 auto;

            @media #{$info-tablet-vertical} {
              width: 100%;
              border-bottom: 2px solid #CACBC6;
            }
            @media #{$info-phone} {
              width: 100%;
              border-bottom: 2px solid #CACBC6;
            }
            li {
                width: 20%;
               @media #{$info-phone} {
                // width: 20%;
                font-size: 13px;
              }
              &.active {
                color: #7C934C;
                position: relative;
                &:after {
                  content: '';
                  position: absolute;
                  left: 0;
                  bottom: -2px;
                  width: 100%;
                  height: 4px;
                  background: #7C934C;
                  /* @media #{$info-phone} {
                    height: 4px;
                  } */
                }
                .cate-pink & {
                  color: #A54B67;
                  &:after {
                    background: #A54B67;
                  }
                }
                .cate-orange & {
                  color: #C66C44;
                  &:after {
                    background: #C66C44;
                  }
                }
              }
            }
          }
        }
      }

  .data-empty {
    font-weight: bold;
    margin-bottom: 50px;
  }
  </style>
