<template>
  <div class="list-container block-horizontal" :class="{ [typeColor]: !!typeColor }" v-if="newList && newList.length">
    <!-- block title New -->
    <div class="block-title" >
       <h2 :class="classTitle">NEW</h2>
      <span :class="classSubTitle">新着</span>
    </div>
    <ListItem :list="newList" hasShowMark isHorizontalAmount hasShadow hasShowMore showDesc showDel mode="horizontal" @handle-link="handleMoreNew"/>
  </div>

  </template>

<script>
import ListItem from '../ListItem.vue'
import { mapState } from 'vuex'
import Common from '@/mixins/common.mixin'

export default {
  name: 'ListNew',
  mixins: [Common],

  components: {
    ListItem
  },

  data () {
    return {
      typeColor: '',
      color: 'green'
    }
  },
  computed: {
    ...mapState('advertises', ['newList']),
    classTitle () {
      return this.color ? (this.color === 'green' ? 'title-green' : 'title-orange') : ''
    },
    classSubTitle () {
      return this.color ? (this.color === 'green' ? 'sub-green' : 'sub-orange') : ''
    }

  },
  methods: {
    handleMoreNew () {
      if (this.checkWebview()) {
        const matchValue = {
          id: 'all',
          '#color': '1',
          '#levelCategory': 'new'
        }
        const url = process.env.VUE_APP_CATEGORY_DOMAIN.replace(/id|#color|#levelCategory/gi, function (key) {
          return matchValue[key]
        })
        this.triggerEventApp(
          'OPEN_WEBVIEW',
          { url: url }
        )
      } else this.$router.push({ name: 'CategoryList.Category', params: { id: 'all' }, query: { typeColor: '1', level: 'new' } })
    }
  }
}
</script>

<style lang="scss" scoped>
  .list-container {
    margin-bottom: 120px;
    &:has(.in-modal) {
      & {
        margin-bottom: 0;
      }
    }
  }
  .block-title {
    h3 {
      label {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 4px;
          background: url("../../assets/images/icon/dot-under-line.svg") no-repeat;
          background-size: contain;
          background-position: center;
          top: 1px;
          left: 0;
        }
      }
    }
  }

  .data-empty {
    font-weight: bold;
    margin-bottom: 50px;
  }
</style>
