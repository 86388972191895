<template>
  <div class="pickup-container block-vertical" :class="{ [typeColor]: !!typeColor }" v-if="pickupList && pickupList.length">
    <!-- block title pickup -->
    <div class="block-title" >
       <h2 :class="classTitle">PICKUP</h2>
      <span :class="classSubTitle">ピックアップ</span>
    </div>
    <ListItem :list="pickupList.map(x => x.advertise)" hasBackground isChangeBackground mode="vertical" :type-color="typeColor"/>
  </div>
  </template>

<script>
import ListItem from '../ListItem.vue'
import { mapState } from 'vuex'

export default {
  name: 'ListPickupNew',

  components: {
    ListItem
  },

  data () {
    return {
      typeColor: 'cate-pink',
      color: ''
    }
  },
  computed: {
    ...mapState('pickup', ['pickupList']),
    classTitle () {
      return this.color ? (this.color === 'green' ? 'title-green' : 'title-orange') : ''
    },
    classSubTitle () {
      return this.color ? (this.color === 'green' ? 'sub-green' : 'sub-orange') : ''
    }

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
  .pickup-container {
    margin-bottom: 120px;
    &:has(.in-modal) {
      & {
        margin-bottom: 0;
      }
    }
  }
  .block-title {
    h3 {
      label {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 4px;
          background: url("../../assets/images/icon/dot-under-line.svg") no-repeat;
          background-size: contain;
          background-position: center;
          top: 1px;
          left: 0;
        }
      }
    }
  }

  .data-empty {
    font-weight: bold;
    margin-bottom: 50px;
  }
</style>
